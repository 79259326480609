import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';
import { Box } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ThemeContext } from '@/contexts/ThemeContext'; // Import the ThemeContextProvider
import { AuthProvider } from '@/contexts/AuthContext';
import '../styles/globals.css';

const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const App = ({ Component, emotionCache, pageProps }) => {
  const [mode, setMode] = useState(null);

  // Load theme from localStorage or set to default
  useEffect(() => {
    const savedMode = localStorage.getItem('themeMode') || 'light';
    setMode(savedMode);
  }, []);

  // Save theme mode to localStorage whenever it changes
  useEffect(() => {
    if (mode !== null) {
      localStorage.setItem('themeMode', mode);
    }
  }, [mode]);

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: mode === 'light' ? 'light' : 'dark',
        },
      }),
    [mode],
  );

  const themeContextValue = useMemo(() => ({ mode, setMode }), [mode, setMode]);

  useEffect(() => {
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);

  if (mode === null) return null;

  return (
    <AuthProvider>
      <CacheProvider value={emotionCache}>
        <ThemeContext.Provider value={themeContextValue}>
          <ThemeProvider theme={theme}>
            <Head>
              <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
              />
            </Head>
            <CssBaseline />
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minHeight: '100vh',
              }}
            >
              <Component {...pageProps} />
            </Box>
          </ThemeProvider>
        </ThemeContext.Provider>
      </CacheProvider>
    </AuthProvider>
  );
};

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  pageProps: PropTypes.object,
  emotionCache: PropTypes.object,
};

App.defaultProps = {
  pageProps: {},
  emotionCache: muiCache,
};

export default App;
